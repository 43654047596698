.map {
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px 0px;
}

.map-disabled {
  filter: grayscale(100%) brightness(90%) contrast(0.5) blur(5px);
}

.overlay-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.static-container {
  position: static;
}

.map-spinner {
  z-index: 99;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px 8px rgba(255, 255, 255, 0.9);
}

.ymaps-2-1-79-balloon__layout, .ymaps-2-1-79-balloon {
  border-radius: 10px;
}

.ymaps-balloon_edit-cluster-link {
  text-decoration: none;
}

.ymaps-2-1-79-b-cluster-tabs.ymaps-2-1-79-b-cluster-content {
  width: 450px !important;
}

.ymaps-2-1-79-b-cluster-tabs__item-body.ymaps-2-1-79-b-cluster-content__body {
  padding-right: 0;
}

.ymaps-2-1-79-b-cluster-tabs__item-footer.ymaps-2-1-79-b-cluster-content__footer {
  padding-right: 0;
}

