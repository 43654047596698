.text-input_container {
  border: 1px solid rgb(163, 163, 163);
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 20px;
}

.text-input_header {
  outline: none;
  font-size: 50px;
  font-family: 'Uni Neue Light', source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  margin-bottom: 10px;
}

.text-input_body {
  outline: none;
  font-size: 40px;
  font-family: 'Uni Neue Light', source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

.text-input_body strong, .text-input_body b {
  font-weight: 900;
}

.text-input_body i {
  font-style: italic;
}

.text-input_body code {
  font-family: Consolas, monospace;
}

span[data-id="cursor"] {
  /* background-color: black; */
  border-left: 1.5px solid rgb(0, 0, 0);
  animation: blink 2s step-start 0s infinite;
}

@keyframes blink {
  50% {
    opacity: 0.2;
  }
}
/* 'Uni Neue Light' */