body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MADE Likes Slab';
  src: local('MADE Likes Slab'), url(./fonts/madelikesslab.ttf) format('truetype');
}

@font-face {
  font-family: 'DS Eraser Cyr';
  src: local('DS Eraser Cyr'), url(./fonts/ds-eraser-cyr.ttf) format('truetype');
}

@font-face {
  font-family: 'Uni Neue Light';
  src: local('Uni Neue Light'), url(./fonts/unineuelight.ttf) format('truetype');
}
