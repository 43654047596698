.main {
  font-size: 30px;
  text-align: center;
  color:#1dcd1d;
  height: calc(100vh - 100px);
}

.header {
  margin-top: 100px;
  margin-bottom: 20px;
  font-size: 40px;
}

.timer-info {
  color: black;
  font-size: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-align: left;
}

.links {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

a:link {
  color: #188b18;
}

a:visited {
  color: rgb(11, 65, 11);
}

a:hover {
  color: rgb(0, 173, 58);
}

a:active {
  color: rgb(1, 97, 6);
}