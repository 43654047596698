.loader {
  border: 5px solid #3498db;
  border-top: 5px solid #f3f3f3;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 10px 8px rgba(255, 255, 255, 0.9);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}