:root {
  --toolbar_background-color: rgb(239, 242, 247);
  --search-input-width: 200px;
  --search-input-width_small-screen: 100%;
}

.map-page_container {
  background-color: rgb(237, 248, 250);
  height: 100vh;
  overflow: hidden;
}

.map-page_controls-container {
  width: auto;
  background-color: var(--toolbar_background-color);
  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: absolute;
  top: 18px;
  left: 25px;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  /* padding: 8px 8px; */
}

@media (max-width: 585px) {
  .map-page_controls-container {
    max-width: 332px;
    flex-wrap: wrap;
  }

  .map-page_input-container {
    width: var(--search-input-width_small-screen) !important;
  }
}

.map-page_button {
  width: 164px;
  color: black;
}

.map-page_date-range-container {
  z-index: 99;
  height: 44px;
  background-color: #ffffff;
  border-radius: 10px;
}

.map-page_date-range-container--active {
  /* height: auto; */
}

.map-page_date-range-container--active > .map-page_date-range {
  height: auto;
  overflow: auto;
}

.map-page_date-range {
  border-radius: 10px;
  height: 44px;
  overflow: hidden;
  background-color: #ffffff;
}

.map-page_date-range .rdrDateDisplayWrapper {
  border-radius: 10px;
}

.map-page_date-range .rdrDateDisplay {
  margin: 5px;
  border-radius: 10px;
}

.map-page_date-range .rdrDateDisplayItem input {
  height: 32px;
}

.map-page_input-container {
  height: 44px;
  padding: 5px;
  border-radius: 10px;
  background-color: var(--toolbar_background-color);
  width: var(--search-input-width);
}

.map-page_input-container input {
  height: 100%;
  width: 100%;
  outline: none;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 4px;
  padding: 4px;
}