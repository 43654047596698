.canvas-container {
  position: relative;
  padding: 0;
  /* width: 100%; */
  /* height: 100%; */
  /* overflow: hidden; */
}

.debug-info {
  position: absolute;
  top: 10px;
  left: 10px;
  color: black;
  z-index: 100;
}

#main-layer {
  position: absolute;
}

#canvas-overlay {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.drawing-tools-container {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgb(247, 247, 247);
  padding: 5px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  z-index: 3;
}

.drawing-tools-container.vertical {
  flex-direction: column;
}

.button {
  height: 30px;
  background-color: white;
  outline: none;
  border: 1px solid lightgrey;
  user-select: none;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 30px;
}

.drawing-tools-container.vertical .button {
  justify-content: space-between;
  width: 100%;
}

.drawing-tools-container.vertical .input-container {
  width: 100%;
}

.button.selected {
  border: 2px solid rgb(173, 217, 247);
}

.button:hover,
.input-container > label:hover {
  filter: brightness(1.05);
  color: rgb(61, 63, 75);
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-size: 15px;
  user-select: none;
}

.input-container label.strikethrough {
  text-decoration: line-through;
}

.can-be-clicked {
  cursor: pointer;
}

.input-container input[type="color"] {
  width: 30px;
  height: 25px;
  border: none;
  padding: 0;
}

.input-container input[type="range"] {
  width: calc(100% - 5px);
}

.input-container input[type="checkbox"] {
  margin-right: 10px;
}

.input-container.vertical {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tool-icon.line {
  transform: rotate(70deg);
  font-size: 20px;
  line-height: 20px;
  display: inline-block;
}

@supports (-moz-appearance: button) and (contain: paint) {
  .tool-icon.line {
    margin-left: 6px;
  }
}

.splitter {
  margin: 0 10px;
  background-color: rgb(221, 221, 221);
  width: 1px;
}

.splitter.vertical {
  margin: 10px 0;
  height: 1px;
  width: 100%;
}

.palette {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgb(247, 247, 247);
  padding: 2px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  max-width: 416px;
  transition: background-color 0.5s ease;
}

.color {
  width: 20px;
  height: 20px;
  border: 1px solid rgb(221, 221, 221);
  margin: 2px;
}

@media (min-width: 720px) and (max-width: 1200px) {
  .drawing-tools-container {
    gap: 5px;
  }

  .drawing-tools-container.vertical {
    width: 150px;
  }

  .button {
    height: 30px;
    min-width: 30px;
    font-size: 14px;
  }

  .drawing-tools-container.vertical .input-container {
    font-size: 14px;
    line-height: 14px;
  }

  .drawing-tools-container.vertical .button span {
    font-size: 15px;
    line-height: 15px;
  }

  .drawing-tools-container .button span {
    font-size: 20px;
    line-height: 20px;
  }

  .input-container {
    height: 30px;
    font-size: 15px;
  }

  .input-container input[type="color"] {
    width: 30px !important;
    height: 25px !important;
    /* margin-left: 10px; */
  }

  .input-container input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
  }

  .tool-icon.line {
    font-size: 25px;
    line-height: 25px;
  }

  .palette {
    max-width: 470px;
  }

  .color {
    width: 25px !important;
    height: 25px !important;
  }

  .splitter {
    margin: 0 3px;
  }

  .splitter.vertical {
    margin: 3px 0;
  }
}

@media (max-width: 720px) {
  .drawing-tools-container {
    gap: 2px;
  }

  .button {
    height: 30px;
    min-width: 30px;
    font-size: 12px;
  }

  .drawing-tools-container.vertical {
    width: 60px;
  }

  .drawing-tools-container.vertical .input-container {
    font-size: 12px;
    line-height: 12px;
  }

  .drawing-tools-container.vertical .button span {
    font-size: 12px;
    line-height: 12px;
  }

  .drawing-tools-container .button span {
    font-size: 20px;
    line-height: 20px;
  }

  .input-container {
    height: 20px;
    font-size: 12px;
  }

  .input-container input[type="color"] {
    width: 30px !important;
    height: 20px !important;
    margin: 0;
  }

  .input-container input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
    margin: 0;
  }

  .input-container.vertical {
    height: auto;
  }

  .palette {
    max-width: 390px;
  }

  .color {
    width: 20px !important;
    height: 20px !important;
  }

  .splitter {
    display: none;
  }
}

/* prevent "pull-to-refresh" action */
#root {
  overscroll-behavior: contain;
}

html {
  touch-action: none;
}
